import React from "react"

import Helmet from "react-helmet"
import Layout from "@/components/layout"

import { HeaderContainer } from "@/components/estates/janowo/janowoStyle"
import EstateTitle from "@/components/estates/estateTitle"
import { Col, Container, Row } from "react-bootstrap"

import { ProgramInfoBarContainer } from "@/components/program/programInfoBar"
import ProgramInfoBarItem from "@/components/program/programInfoBarItem"
import ProgramInfoBarArrow from "@/components/program/programInfoBarArrow"
import ContactFormDesign from "@/components/shared/contactForm/contactFormDesign"
import CompanyTiles from "@/components/company/companyTiles"
import EstateSlider from "@/components/estates/estateSlider"
import img1 from "@/images/z-18/pod-klucz/WpK_salon_pakiet_platynowy.jpg"
import img2 from "@/images/z-18/pod-klucz/WpK_salon_pakiet_zloty.jpg"
import img3 from "@/images/z-18/pod-klucz/WpK_salon_pakiet_srebrny.jpg"
import img4 from "@/images/z-18/pod-klucz/WpK_lazienka_pakiet_platynowy.jpg"
import img5 from "@/images/z-18/pod-klucz/WpK_lazienka_pakiet_zloty.jpg"
import img6 from "@/images/z-18/pod-klucz/WpK_lazienka_pakiet_srebrny.jpg"
import img7 from "@/images/z-18/pod-klucz/Lazienka_V3_umywalka.jpg"
import img8 from "@/images/z-18/pod-klucz/OGWII_S1-11.2_lazienka_parter_01.jpg"
import img9 from "@/images/z-18/pod-klucz/OGWII_S1-11.2_lazienka_parter_02.jpg"
import img10 from "@/images/z-18/pod-klucz/OGWII_S1-11.2_lazienka_pietro_01.jpg"
import img11 from "@/images/z-18/pod-klucz/OGWII_S1-11.2_lazienka_pietro_02.jpg"

const OfferIndexPage = () => {
  const slides = [
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img5 },
    { img: img6 },
    { img: img7 },
    { img: img8 },
    { img: img9 },
    { img: img10 },
    { img: img11 },
  ]
  return (
    <>
      <Helmet>
        <title>WILLBUD | WYKOŃCZENIE POD KLUCZ</title>
      </Helmet>
      <Layout>
        <HeaderContainer style={{ marginBottom: 0 }}>
          <Container>
            <Row>
              <Col className="content-wrapper">
                <EstateTitle mainTitle="WYKOŃCZENIE POD KLUCZ " />
              </Col>
            </Row>
            <Row style={{ alignItems: "end" }}>
              <Col className="content-wrapper" xs="12" sm="12" md="12" lg="4">
                <EstateTitle
                  subTitleBold="Wykończenie pod klucz w 10 tygodni"
                  subtitle="Stworzyliśmy kompleksową usługę doboru i realizacji wykończeń materiałowych lokalu łącznie z drzwiami wewnętrznymi, pełną armaturą i przyborami sanitarnymi łazienki. To wielka oszczędność czasu i stresu, o czym przekonał się każdy, kto miał do czynienia z remontem. "
                />
              </Col>
              <Col xs="12" sm="12" md="12" lg="8">
                <ul style={{ marginBottom: 0 }}>
                  <li>
                    Nasza usługa opiera się na osobnej umowie i może zostać
                    zawarta po zakupie lokalu.
                  </li>
                  <li>
                    Do wyboru aż trzy pakiety wykończeniowe z możliwością
                    indywidualnego ich rozszerzenia o dobór opraw
                    oświetleniowych czy projekt zabudowy kuchennej i wnękowej.
                  </li>
                  <li>
                    Oferujemy produkty znanych marek jak: Koło, Rocca, Grohe,
                    QuickStep, Arbitron, Marazzi, Porta, DRE, Pol-Skone i wiele
                    innych.
                  </li>
                  <li>
                    Profesjonalne ekipy wykonawcze pod nadzorem
                    budowlano-autorskim gwarantują realizację usługi do 10
                    tygodni od dnia przekazania lokalu.
                  </li>
                  <li>
                    Istnieje możliwość jednoczesnego wprowadzania zmian
                    aranżacyjnych i opracowywania pakietu wykończeniowego, aby
                    szybko i sprawnie zrealizować wymarzone wnętrza i cieszyć
                    się nimi jak najszybciej po zakończeniu inwestycji.
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
          <div className="slider-wrapper">
            <Container>
              <Row>
                <Col>
                  <EstateSlider slides={slides} zoom={true} />
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <Row style={{ marginBottom: "40px" }}>
              <ProgramInfoBarContainer five>
                <h2>Proces</h2>
                <div className="wrapper">
                  <ProgramInfoBarItem>
                    <h3>PODPISUJEMY UMOWĘ</h3>
                  </ProgramInfoBarItem>

                  <ProgramInfoBarArrow />
                  <ProgramInfoBarItem>
                    <h3>PRZEDSTAWIASZ NAM SWOJE POMYSŁY</h3>
                  </ProgramInfoBarItem>
                  <ProgramInfoBarArrow />
                  <ProgramInfoBarItem>
                    <h3>TWORZYMY DLA CIEBIE PROJEKT WYKOŃCZENIOWY</h3>
                  </ProgramInfoBarItem>
                  <ProgramInfoBarArrow />
                  <ProgramInfoBarItem>
                    <h3>REALIZUJEMY PROJEKT W 10 TYGODNI</h3>
                  </ProgramInfoBarItem>
                  <ProgramInfoBarArrow />
                  <ProgramInfoBarItem>
                    <h3>ODBIERASZ GOTOWE MIESZKANIE</h3>
                  </ProgramInfoBarItem>
                </div>
              </ProgramInfoBarContainer>
            </Row>
          </Container>

          <div className="form-wrapper">
            <Container>
              <Row>
                <Col className="form">
                  <ContactFormDesign />
                </Col>
              </Row>
            </Container>
          </div>
          <CompanyTiles />
        </HeaderContainer>
      </Layout>
    </>
  )
}

export default OfferIndexPage
